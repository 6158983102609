import * as React from "react";
import "../styles/features.css";

import maskIcon from "../assets/wear-mask.png";
import handWashIcon from "../assets/hand-wash.png";
import openWindowIcon from "../assets/open-window.png";
import limitPeopleIcon from "../assets/limit-pple.png";
import sneezeIcon from "../assets/sneeze-cover.png";
import wipeIcon from "../assets/wipe-clean.png";
import samePeopleIcon from "../assets/same-people.png";
import sixFeetIcon from "../assets/six-feet.png";

interface Props {}

export const COVID19: React.FC<Props> = () => {
  return (
    <div>
      <div className="container" id="for-employers">
        <div className="py-32">
          <h2 className="text-4xl text-blue-600">
            COVID19 <b>Guidelines</b>
          </h2>
          <p className="text-2xl my-8 text-gray-900">
            You are safer from COVID19 when you share a ride with us because our
            drivers and passengers on average take only two trips or less per
            day and are not exposed to crowded parks and public transport. Here
            are some tips keep safe while sharing a ride on SafariShare.
          </p>

          <div className="grid grid-cols-2 gap-4">
            <div className="my-2 flex flex-wrap flex-row">
              <img src={maskIcon} className="h-20 filter" alt="Mask" />
              <p className="pt-6 pl-10">
                <b>WEAR MASKS </b> in a shared vehicle
              </p>
            </div>
            <div className="my-2 flex flex-wrap flex-row">
              <img src={handWashIcon} className="h-20 filter" alt="Hygiene" />
              <p className="pt-6 pl-10">
                Use proper <b>HAND HYGIENE</b>
              </p>
            </div>
            <div className="my-2 flex flex-wrap flex-row">
              <img
                src={openWindowIcon}
                className="h-20 filter"
                alt="Fresh-air"
              />
              <p className="pt-6 pl-10">
                Use <b>FRESH AIR</b> through <b>VENTS</b> or <b>WINDOWS</b>
              </p>
            </div>
            <div className="my-2 flex flex-wrap flex-row">
              <img
                src={limitPeopleIcon}
                className="h-20 filter"
                alt="Limit-people"
              />
              <p className="pt-6 pl-10">
                <b>LIMIT THE NUMBER</b> of people
              </p>
            </div>
            <div className="my-2 flex flex-wrap flex-row">
              <img
                src={sneezeIcon}
                className="h-20 filter"
                alt="Cover-coughs"
              />
              <p className="pt-6 pl-10">
                <b>COVER COUGHS</b> and <b>SNEEZES</b>
              </p>
            </div>
            <div className="my-2 flex flex-wrap flex-row">
              <img src={wipeIcon} className="h-20 filter" alt="Clean" />
              <p className="pt-6 pl-10">
                <b>CLEAN</b> and <b>DISINFECT</b> surfaces often
              </p>
            </div>
            <div className="my-2 flex flex-wrap flex-row">
              <img
                src={samePeopleIcon}
                className="h-20 filter"
                alt="Same-people"
              />
              <p className="pt-6 pl-10">
                <b>RIDE</b> to work <b>WITH</b> the <b>SAME PEOPLE</b>
              </p>
            </div>
            <div className="my-2 flex flex-wrap flex-row">
              <img src={sixFeetIcon} className="h-20 filter" alt="Six-feet" />
              <p className="pt-6 pl-10">
                Stay atleast <b>SIX FEET APART</b> while waiting
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
