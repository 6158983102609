import React from "react";
import { RegistrationForm } from "../../components/registration-form";

export const DriverRegistration = () => {
  return (
    <RegistrationForm
      type="driver"
      title="Do you frequently drive long distance with empty seats?"
      description="Join SafariShare as a driver today and save money on your trips"
    />
  );
};
