import * as React from "react";
import { Helmet } from "react-helmet";

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { TermsAndConditions } from "../components/ts-and-cs";

export const Terms: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>SafariShare &mdash; Terms of Use</title>
      </Helmet>
      <Navigation />
      <TermsAndConditions />
      <Footer />
    </div>
  );
};
