import * as React from "react";
import appstore from "../assets/appstore.svg";
import playstore from "../assets/playstore.png";

interface Props {
  message?: string;
}
export const AppDownloadLinks: React.FC<Props> = ({ message }) => {
  return (
    <div className="md:container md:mx-auto px-8 py-12">
      <div className="py-12">
        {message && (
          <h2 className="text-2xl md:w-3/5 mx-auto text-center font-bold">
            {message}
          </h2>
        )}
        <p className="mt-2 text-lg text-center text-gray-600">
          You can now download the app and sign in with your account phone
          number
        </p>
        {/** playstore & appstore */}
        <div className="flex justify-center">
          <a href="https://play.google.com/store/apps/details?id=co.safarishare.app">
            <img
              className="h-48 w-48 object-contain"
              src={playstore}
              alt="playstore"
            />
          </a>

          <div className="mx-2"></div>

          <a href="https://apps.apple.com/ug/app/safarishare/id1545451416">
            <img
              className="h-48 w-48 object-contain"
              src={appstore}
              alt="appstore"
            />
          </a>
        </div>
        <h3 className="px-6 text-center font-semibold text-2xl">
          Available on PlayStore and AppStore.
        </h3>
      </div>
    </div>
  );
};
