import * as React from "react";
import { Helmet } from "react-helmet";

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { PrivacyCopy } from "../components/privacy";

export const Privacy: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>SafariShare &mdash; Privacy Policy</title>
      </Helmet>
      <Navigation />
      <PrivacyCopy />
      <Footer />
    </div>
  );
};
