import * as React from "react";
import { Helmet } from "react-helmet";

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { COVID19 } from "../components/covid19";

export const Guidelines: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>SafariShare &mdash; COVID19 Guidelines</title>
      </Helmet>
      <Navigation />
      <COVID19 />
      <Footer />
    </div>
  );
};
