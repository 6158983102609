import * as React from "react";
import Jumbotron from "./jumbotron";

export const TermsAndConditions = () => {
  return (
    <React.Fragment>
      <Jumbotron
        header="Terms of Use"
        leadingParagraph="Read more about our terms of use"
      />

      <div className="md:container md:mx-auto sm:pb-52 pb-4 px-4">
        <p className=" mb-2">
          These <b> Terms of Use (&ldquo;Terms&rdquo;)</b> govern your rights
          and obligations regarding the use of SafariShare&rsquo;s Software
          (&ldquo;Software&rdquo;) and service (both collectively referred to as
          the &ldquo;Service&rdquo;) on the Internet or in cellular media. These
          Terms constitute a fully binding agreement between Voyage Technologies
          Limited&nbsp;(including its affiliates and subsidiaries,
          &ldquo;SafariShare&rdquo; or &ldquo;We&rdquo;) the proprietor of all
          rights in and to the Service, and you. It is therefore recommended
          that you carefully read these Terms.
        </p>
        <p className="mb-2">
          By using the SafariShare Service, you signify your assent to these
          Terms;
        </p>

        <ol className=" list-disc mb-2">
          <li className=" ml-4">
            <a href="/privacy">SafariShare&rsquo;s privacy policy</a>{" "}
            (&ldquo;Privacy Policy&rdquo;); and
          </li>
          <li className="ml-4">
            all of which are an integral part of these Terms.
          </li>
        </ol>
        <p>
          If you do not agree to these Terms or any of its parts, then you are
          prohibited and must refrain from using the Service.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">KEY POINTS</h4>

        <p className="mb-2">
          The following key points of the Terms are highlighted here for your
          convenience only. <br /> These key points are not made in lieu of the
          full Terms and their presence in this section does not mean that they
          are intended to supersede or override any other terms or conditions
          provided by SafariShare..
        </p>

        <ul className=" list-decimal ml-4">
          <li>
            <h4 className=" font-semibold text-lg">
              Road information prevails.
            </h4>

            <p className=" mb-2">
              The information provided by the Service is not intended to replace
              the information provided on the road, such as travel direction,
              time-based restrictions, lane restrictions, road blockades,
              traffic signs, traffic lights, police instructions, etc.
            </p>
          </li>

          <li>
            <h4 className=" font-semibold text-lg">Cautious driving</h4>
            <p className=" mb-2">
              Always drive vigilantly according to road conditions and in
              accordance with traffic laws. It is strictly forbidden to send
              traffic updates (such as updates on road accidents and traffic
              congestion) or to non-verbally interact with the Service or use
              the Service in a non-verbal manner for any purpose other than
              navigation while driving. Traffic updates or non-verbal reports
              you want to submit to the Service may only be sent after you have
              stopped your vehicle in an appropriate location permitted by law.
              Alternatively, such updates may be sent by a passenger other than
              the driver, provided it does not interfere with the due course of
              driving and does not distract the driver&rsquo;s attention to the
              road.
            </p>
          </li>
          <li>
            <h4 className=" font-semibold text-lg">Non-continuous updates.</h4>
            <p className="mb-2">
              The information provided by the Service originates from other
              users of the Service. Such information is intrinsically fluctuant
              and may be inaccurate, incomplete or outdated. SafariShare does
              not provide any warranties to such information&rsquo;s credibility
              or reliability.
            </p>
          </li>

          <li>
            <h4 className=" font-semibold text-lg">Location-based Service.</h4>

            <p className="mb-2">
              Some features of the Service make use of detailed location and
              route information, for example in the form of GPS signals and
              other information sent by your mobile device on which the
              SafariShare application is installed and activated. These features
              cannot be provided without utilizing this technology. Please note,
              as described in detail in the{" "}
              <a href="/privacy">Privacy Policy</a>
            </p>
            <ul className=" list-disc ml-4 mb-2">
              <li>
                SafariShare uses your location and route information to create a
                detailed route history of all of your journeys made when using
                the Service. SafariShare uses this history to offer the Service
                to you, to improve the quality of the Service it offers to you
                and to all of its users, to improve the accuracy of its mapping
                and navigation data, and more as described in detail in the{" "}
                <a href="/privacy">Privacy Policy</a>. This history is
                associated with your account and username (if you have chosen to
                set up a username). This history is retained by SafariShare for
                a limited period of time and in accordance with the{" "}
                <a href="/privacy">Privacy Policy</a>.
              </li>
              <li>
                SafariShare allows you to use the Service whether or not you
                choose to set up a username for yourself. If you choose to use
                the Service without setting up a username you may do so by
                skipping the username setup stage of the application
                installation process. SafariShare will still link all of your
                information with your account and a unique identifier generated
                by SafariShare in accordance with the{" "}
                <a href="/privacy" className="underline text-blue-600">
                  Privacy Policy
                </a>
                . Note that in order to access certain features of the Service
                (for example map editing, ranking, moods) you will need to set
                up a username.
              </li>
            </ul>
          </li>
          <li className="mb-2">
            Third-party advertisements may appear on the Service from time to
            time. SafariShare does not endorse these advertisements, and the
            advertisements are not intended to be, nor should they be, viewed by
            drivers unless and until their vehicle has come to a complete stop.
          </li>
          <li className="mb-2">
            The Internet connection required to use the Service, and any
            associated charges (e.g. mobile data expenses) incurred by your use
            of the Service are your exclusive responsibility and made solely at
            your expense. Transmitting and receiving real-time updates to and
            from the Service requires an online (e.g. Wi-Fi, 3G, 4G) connection
            between your cellular device and the Internet. The expenses of such
            connection are as prescribed by the agreement between you and your
            communication service provider (such as your cellular company), and
            according to its applicable terms of payment.
          </li>

          <li>
            <h4 className=" font-semibold text-lg">Free Software</h4>
            <p className="mb-2">
              The Service utilizes Software for map displays, updates and road
              information. With respect to some cellular telephone devices
              running the Software, the Software is free software. In such
              cases, you may redistribute the Software or modify it in
              accordance with the GNU General Public License as published by the
              Free Software Foundation, whether it is version 2.0 of the license
              or any later version of your choice. For further information, see
              the GNU General Public License. The Service, its database,
              SafariShare&rsquo;s trademarks, the design of the maps of the
              Service and the voice files integrated in the Software &ndash; all
              are not free-software.
            </p>
          </li>

          <li>
            <h4 className=" font-semibold text-lg">Your age.</h4>
            <p className="mb-2">
              The Service is intended for use by users who are of the legal age
              required to hold a driving license. In any case, to use our
              Service you must be 16 years of age or older. If you are under 16,
              you may not download or use the Service. Accounts of users under
              the age of 16 years will be cancelled and deleted by SafariShare,
              upon receiving notice.
            </p>
          </li>
          <li>
            Your privacy is important to us. While using the Service, personal
            information may be provided by You or collected by SafariShare as
            detailed in our{" "}
            <a href="/privacy" className="underline text-blue-600">
              Privacy Policy
            </a>
            . The{" "}
            <a href="/privacy" className="underline text-blue-600">
              Privacy Policy{" "}
            </a>{" "}
            explains&nbsp;our practices pertaining to the use of your personal
            information and we ask that you read such{" "}
            <a href="/privacy" className="underline text-blue-600">
              Privacy Policy
            </a>
            &nbsp;carefully. By accepting these Terms, you hereby acknowledge
            and agree to the collection, storage and use of your personal
            information by SafariShare, subject to this section, the{" "}
            <a href="/privacy" className="underline text-blue-600">
              Privacy Policy
            </a>
            &nbsp;and any applicable laws and regulation.
          </li>
        </ul>

        <h4 className="text-xl font-bold mt-8 mb-2">WHAT IS THE SERVICE</h4>
        <p>
          The Service allows car drivers to navigate to their desired address.
          It allows drivers and users to assemble and update maps to reflect
          road conditions and occurrences on them, such as traffic congestions,
          road accidents, police roadblocks, etc. The Service is provided mainly
          through the Software.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">THE LICENSE</h4>

        <h4 className=" font-semibold text-lg">SafariShare Service.</h4>
        <p className=" mb-2">
          SafariShare hereby grants you a free of charge, non-exclusive,
          time-limited, non-transferable, non-sublicensable, revocable license
          to use the Service (including the Software) for non-commercial
          purposes, subject to these Terms.
        </p>

        <h4 className=" font-semibold text-lg">
          The free version of the Software.
        </h4>

        <p className=" mb-2">
          In respect to the cellular telephones using the free-software version
          of the Software, the Software is free software; you can redistribute
          it and/or modify it under the terms of the GNU General Public License
          as published by the Free Software Foundation; either version 2 of the
          License, or (at your option) any later version. This program is
          distributed in the hope that it will be useful, but WITHOUT ANY
          WARRANTY; without even the implied warranty of MERCHANTABILITY or
          FITNESS FOR A PARTICULAR PURPOSE. See the GNU General Public License
          for more details.
        </p>

        <p>
          Along with this Software, you should have received a copy of the GNU
          General Public License; in case you did not receive such a copy,
          please contact us at{" "}
          <a
            href="mailto:support@safarishare.co"
            className=" underline text-blue-600"
          >
            support@safarishare.co
          </a>
          , advise us of your email address and we will send you a copy of the
          GNU General Public License. If you are using the Software, you may
          contact us at the above address, request a copy of the source code of
          the Software and we will send you such a copy. This offer was valid
          for three years (from the date you last obtained delivery of the free
          software version of the Software). Be advised that this clause applies
          only with respect to the free version of the Software. It does not
          apply to the Service, its database, SafariShare&rsquo;s trademarks,
          the design of the maps of the Service and the voice files integrated
          in the Software or to other intellectual property rights of
          SafariShare &ndash; all of these are not free-software.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">USING THE SERVICE</h4>
        <p>
          You may use the Service solely for private and personal purposes. You
          may not use the Service commercially.
          <p>
            {" "}
            For example, you may not:
            <ul className=" list-disc mb-2.5 ml-4">
              <li>
                offer to third parties a service of your own that uses the
                Service;
              </li>
              <li>resell the Service;</li>
              <li>offer to rent or lease the Service; or</li>
              <li>
                offer the Service to the public via communication or integrate
                it within a service of your own,
              </li>
              without the prior written consent of SafariShare.{" "}
            </ul>
            For clarity, the examples listed are made for illustrative purposes
            only; they do not constitute an exhaustive list of restricted
            activities involving the Service.
          </p>
        </p>
        <p className="mb-2">
          You may not copy, print, save or otherwise use data from the Site or
          the Service&rsquo;s database. This clause does not limit the use of
          the database as intended by the Software and for the purposes of
          private and personal use of the Service.
        </p>
        <p className="mb-2">
          When using the Service or the Site you may not engage in scraping,
          data mining, harvesting, screen scraping, data aggregating, and
          indexing. You agree that you will not use any robot, spider, scraper
          or other automated means to access the Site or the Service&rsquo;s
          database for any purpose without the express prior written permission
          of SafariShare.
        </p>

        <p>
          The Software may not be used in any way that is not expressly
          permitted by these Terms.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">USE RESTRICTIONS</h4>
        <p className="mb-2">
          There are certain types of conduct that are strictly prohibited on the
          Service. Please read the following restrictions carefully. Your
          failure to comply with the provisions set forth below may result (at
          SafariShare&rsquo;s sole discretion) in the termination of your access
          to the Service and may also expose you to civil and/or criminal
          liability.
        </p>
        <p className="mb-2">
          You may not, whether yourself or through any other means or person:
        </p>
        <p className="mb-2">
          (i) copy, modify, adapt, translate, reverse engineer, decompile, or
          disassemble any portion of the Content included in the Service and/or
          Site, or in any way or publicly display, perform, or distribute them;
        </p>
        <p className="mb-2">
          (ii) make any use of the Content on any other website or networked
          computer environment for any purpose, or replicate or copy the Content
          without SafariShare&rsquo;s prior written consent;
        </p>
        <p className="mb-2">
          (iii) create a browser or border environment around the Content (e.g.
          no frames or inline linking);
        </p>
        <p className="mb-2">
          (iv) interfere with or violate any third party or other user&rsquo;s
          right to privacy or other rights, including copyrights and any other
          intellectual property rights of others, or harvest or collect personal
          information about visitors or users of the Service and/or Site without
          their express consent, including using any robot, spider, site search
          or retrieval application, or other manual or automatic device or
          process to retrieve, index, or data-mine;
        </p>
        <p className="mb-2">
          (v) defame, abuse, harass, stalk, threaten, or otherwise violate the
          legal rights of others, including others&rsquo; copyrights, and other
          intellectual property rights;
        </p>
        <p className="mb-2">
          (vi) transmit or otherwise make available in connection with the
          Service and/or Site any virus, worm, Trojan Horse, time bomb, web bug,
          spyware, or any other computer code, file, or program that may or is
          intended to damage or hijack the operation of any hardware, software,
          or telecommunications equipment, or any other actually or potentially
          harmful, disruptive, or invasive code or component;
        </p>
        <p className="mb-2">
          (vii) interfere with or disrupt the operation of the Service and/or
          Site, or the servers or networks that host the Service and/or Site or
          make the Service and/or Site available, or disobey any requirements,
          procedures, policies, or regulations of such servers or networks;
        </p>
        <p className="mb-2">
          (viii) sell, license, or exploit for any commercial purposes any use
          of or access to the Content and/or the Service and/or Site;
        </p>
        <p className="mb-2">
          (ix) frame or mirror any part of the Service and/or Site without
          SafariShare&rsquo;s prior express written authorization;
        </p>
        <p className="mb-2">
          (x) create a database by systematically downloading and storing all or
          any of the Content from the Service and/or Site;
        </p>
        <p className="mb-2">
          (xi) forward any data generated from the Service and/or Site without
          the prior written consent of SafariShare;
        </p>
        <p className="mb-2">
          (xii) transfer or assign your Service accounts&rsquo; password, even
          temporarily, to a third party;
        </p>
        <p className="mb-2">
          (xiii) use the Service and/or Site for any illegal, immoral or
          unauthorized purpose;
        </p>
        <p className="mb-2">
          (xiv) use the Site, the Service, or the Content for non-personal or
          commercial purposes without SafariShare&rsquo;s express prior written
          consent; or
        </p>
        <p>(xv) infringe or violate any of these Terms.</p>

        <h4 className="text-xl font-bold mt-8 mb-2">
          TERMINATION OF USE OF THE SERVICE
        </h4>
        <p>
          You may terminate your use of the Service at any time and for whatever
          reason. You are not obligated to advise SafariShare of such
          termination. However, if you would also like for SafariShare to delete
          your SafariShare account and your personal information contained in
          the account, please use the &ldquo;Delete Account&rdquo; function in
          the in app Privacy Settings or Account &amp; Login Settings. On
          receiving such a request, SafariShare will use reasonable efforts to
          delete such information, however please note that information may not
          be deleted immediately from our back-up systems. For more information,
          please read our{" "}
          <a
            href="https://www.waze.com/legal/privacy"
            className=" underline text-blue-600"
          >
            Privacy Policy
          </a>
          .
        </p>
        <p>
          SafariShare retains the right to block your access to the Service and
          discontinue your use of the Service, at any time and for any reason
          SafariShare deems appropriate, at its sole and absolute discretion.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">USER CONTENT</h4>
        <p>
          The Service allows all users of the Software to submit and post
          information and content to other users (&ldquo;Content&rdquo;).
          Content is separate from third party sponsored advertisements that may
          appear on the Service. Content can include, for example, map and road
          updates, traffic congestion updates, road accidents, etc. You assume
          sole responsibility for any Content you post and you alone are liable
          for the consequences when you post Content.
        </p>
        <p>
          ALWAYS DRIVE VIGILANTLY ACCORDING TO ROAD CONDITIONS AND IN ACCORDANCE
          WITH TRAFFIC LAWS. IT IS STRICTLY FORBIDDEN TO SEND ANY CONTENT -
          INCLUDING TRAFFIC UPDATES (SUCH AS UPDATES ON ROAD ACCIDENTS AND
          TRAFFIC CONGESTION)- WHILE DRIVING. YOUR CONTENT SUBMISSIONS MAY ONLY
          BE SENT AFTER YOU HAVE STOPPED YOUR VEHICLE IN AN APPROPRIATE LOCATION
          PERMITTED BY LAW. ALTERNATIVELY, CONTENT MAY BE SENT BY A PASSENGER
          OTHER THAN THE DRIVER, PROVIDED IT DOES NOT INTERFERE WITH THE DUE
          COURSE OF DRIVING AND DOES NOT DISTRACT THE DRIVER.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">FORBIDDEN POSTS</h4>
        <p>
          It is forbidden to submit Content of a commercial nature (including
          advertising), unless such posts pertain to SafariShare, the Service,
          or SafariShare&rsquo;s products, and such Content strictly complies
          with these Terms.
        </p>
        <p>
          When you submit Content to be published by the Service, you must make
          sure it is lawful. For example, you may not submit Content that:
        </p>
        <ul className=" list-disc ml-4 mb-2.5">
          <li className="mb-2">
            is diminishing or infringing proprietary rights of others, including
            but not limited to copyright and trademarks; poses a risk to a
            person&rsquo;s
          </li>
          <li className="mb-2">
            safety, security or health; identifies other persons without
            obtaining such person&rsquo;s express written consent to the
            disclosure of their personal
          </li>
          <li className="mb-2">
            information, or pertains to minors and identifies minors or their
            personal information, including their full name, age, address or
            contact information;
          </li>
          <li className="mb-2">
            is unlawful, defamatory, libellous or invades the privacy of others;
            is harassing, offensive, threatening or vulgar; is characterized by,
            or that
          </li>
          <li className="mb-2">
            encourages racism or unlawfully discriminates on the basis of race,
            origin, ethnicity, nationality, religion, gender, occupation, sexual
            orientation, illness, physical or mental disability, faith,
            political view or socio-economical class;
          </li>
          <li className="mb-2">
            encourages criminal behaviour or conduct that would constitute a
            criminal offence under any law, or could give rise to civil
            liability or other lawsuits;
          </li>
          <li className="mb-2">
            promotes pyramid schemes, chain letters or disruptive commercial
            messages or advertisements, or anything else prohibited by law or
            under these Terms;
          </li>
          <li className="mb-2">
            falsely expresses or implies that such content is sponsored or
            endorsed by SafariShare.
          </li>
        </ul>
        <p>
          The foregoing examples of unlawful Content are made solely for
          illustrative purposes and do not constitute an exhaustive list of
          restricted Content.
        </p>
        <p>
          SafariShare may decline to publish, or immediately delete any Content
          you submit if you or the Content have violated these Terms or if you
          engaged in a commission or omission that is harmful or may be harmful
          to the Service, its users, SafariShare or any of its agents or
          affiliates. In such cases, SafariShare may also terminate your access
          to the Service or prevent you from posting additional Content on the
          Service. The provisions of this clause are made in addition to any
          rights afforded to SafariShare by any law.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">RIGHTS IN CONTENT</h4>
        <p className=" mb-2">
          When you submit Content to be published on the Service, you represent
          and warrant that you own all intellectual property rights in the
          Content; that you are permitted to publish the Content and to permit
          SafariShare to publish the Content and exploit all intellectual
          property rights in and to the Content. SafariShare receives no
          ownership rights in and to the Content that you submit.
        </p>

        <p className=" mb-2">
          However, by submitting Content to SafariShare, you hereby grant
          SafariShare and the users of the Service an irrevocable, worldwide,
          perpetual, non-exclusive, royalty-free, sub-licensable and
          transferable license to use, copy, distribute, prepare derivative
          works, display in public and publicly perform the Content. The license
          granted to SafariShare in and to the Content you submit is not limited
          to personal use, but also extends to any commercial use of Content, at
          SafariShare&rsquo;s sole and absolute discretion. However, other users
          may only use your Content for non-commercial purposes, unless
          SafariShare provides them with prior written consent to use your
          Content for commercial purposes (for the purpose of which you
          authorize SafariShare to be your agent).
        </p>
        <h4 className="text-xl font-bold mt-8 mb-2">EXAMINATION OF CONTENT</h4>
        <p>
          SafariShare may examine the Content before or after its publication,
          prevent publication of inappropriate or otherwise inadequate or
          erroneous Content, or remove such Content after its publication.
          SafariShare does not normally utilize its right to monitor Content and
          does so only in rare cases.
        </p>
        <p>
          SafariShare retains sole discretion in determining which Content will
          be published, the duration of its publication, its location, how and
          when it appears on the Service, its design and any other matter
          pertaining to the publication of Content within the Service.
          SafariShare does not guarantee that all Content will be published, in
          general, or for any limited time.
        </p>
        <p>
          Content submitted by users for publication does not reflect the views
          of SafariShare. Publishing Content does not warrant its validity,
          reliability, accuracy, legality or it being up-to-date.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">COPYRIGHT</h4>
        <p className="mb-2">
          All intellectual property rights in and to the Site, the Service and
          its database, including copyrights, trademarks, industrial designs,
          patents and trade secrets &ndash; are either the exclusive property of
          SafariShare or its affiliates or are exclusively licensed to
          SafariShare. The Service is protected, among others, by the Israeli
          Copyright Law, 2007 as well as by applicable copyright provisions
          prescribed by any other law, in Israel and elsewhere.
        </p>
        <p className="mb-2">
          &ldquo;SafariShare&rdquo;, the SafariShare logo, and other trade
          and/or service marks are the property of SafariShare or its affiliates
          and you may not use such logos or marks for any purpose that is not
          expressly authorized in these Terms without the prior written consent
          of SafariShare.
        </p>
        <p className="mb-2">
          The design, trade dress, and the &lsquo;look and feel&rsquo; of the
          maps of the Site and the Service are protected works under applicable
          copyright laws and SafariShare and its affiliates retain all
          intellectual property rights in them. The Software license granted to
          you in these Terms does not extend to or include a license to use the
          maps displayed on the Software or any mark, indicator, logo or
          notation embedded in the maps that are displayed on the Software. You
          may not copy or print more than one copy of any data or material
          appearing on the Site.
        </p>
        <p className="mb-2">
          SafariShare may protect the Service by technological means intended to
          prevent unauthorized use of the Service. You undertake not to
          circumvent these means. Without derogating from SafariShare&rsquo;s
          rights under these Terms or under any applicable law, you are advised
          that any attempted or actual infringement of this provision will
          result in the termination of all your rights under these Terms. If you
          circumvent any of the means taken by SafariShare to protect the
          Service from unauthorized use, you must immediately cease any and all
          use of the Service, and you undertake to do so.
        </p>
        <h4 className="text-xl font-bold mt-8 mb-2">APPLE</h4>
        <p>
          If you use the Service on an Apple device, then you agree and
          acknowledge that:
        </p>
        <ul>
          <li>
            Apple, Inc. bears no duties or obligations to you under the Terms,
            including, but not limited to, any obligation to furnish you with
            Service maintenance and support;
          </li>
          <li>
            You will have no claims, and you waive any and all rights and causes
            of action against Apple with respect to the Service or the Terms,
            including, but not limited to claims related to maintenance and
            support, intellectual property infringement, liability, consumer
            protection, or regulatory or legal conformance;
          </li>
          <li>
            Apple and Apple&rsquo;s subsidiaries are third-party beneficiaries
            of the Terms. Upon your acceptance of the Terms, Apple will have the
            right (and will be deemed to have accepted the right) to enforce
            these Terms against you as a third-party beneficiary thereof.
          </li>
        </ul>
        <h4 className="text-xl font-bold mt-8 mb-2">EXPORT CONTROL</h4>
        <p>
          You represent and warrant that: (i) you are not located in a country
          that is subject to a U.S. Government embargo, or that has been
          designated by the U.S. Government as a &ldquo;terrorist
          supporting&rdquo; country; and (ii) you are not listed on any U.S.
          Government list of prohibited or restricted parties.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">
          LIMITATION OF LIABILITY AND WARRANTY
        </h4>
        <p className="mb-2">
          SafariShare PROVIDES THE SERVICE AND CONTENT INCLUDED THEREIN FOR USE
          ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. THEY
          CANNOT BE CUSTOMIZED TO FULFILL THE NEEDS OF EACH AND EVERY USER. WE
          HEREBY DISCLAIM ALL WARRANTIES AND REPRESENTATIONS, EITHER EXPRESS OR
          IMPLIED, WITH RESPECT TO THE SERVICE, INCLUDING, WITHOUT LIMITATION,
          ANY WARRANTIES OF MERCHANTABILITY, AND FITNESS FOR A PARTICULAR
          PURPOSE, FEATURES, QUALITY, NON-INFRINGEMENT, TITLE, COMPATIBILITY,
          PERFORMANCE, SECURITY OR ACCURACY.
        </p>
        <p className="mb-2">
          Additionally and without derogating from the above clause, SafariShare
          disclaims any warranties relating to the accuracy of the maps,
          Content, road conditions, driving directions, or navigation routes
          presented or displayed in or by the Service. For instance, traffic may
          be congested in roads depicted by the Service as uncongested; existing
          roads may be missing from the map; users may submit faulty or
          inaccurate Content or reports. Such errors and omissions are inherent
          to any community-based service that operates on users&rsquo; posts and
          on the information provided by them.
        </p>
        <p className="mb-2">
          You agree and acknowledge that you assume full, exclusive and sole
          responsibility for the use of and reliance on the Service, and you
          further agree and acknowledge that your use of or reliance on the
          Service is made entirely at your own risk. You further acknowledge
          that it is your responsibility to comply with all applicable laws
          (including traffic laws) while using the Service.
        </p>
        <p className="mb-2">
          THE INFORMATION PROVIDED BY THE SERVICE IS NOT INTENDED TO REPLACE THE
          INFORMATION PRESENTED ON THE ROAD. IN THE EVENT THAT THE INFORMATION
          PRESENTED ON THE ROAD (TRAFFIC LIGHTS, TRAFFIC SIGNS, POLICE PERSON,
          ETC.) INSTRUCTS DIFFERENTLY THAN THE SERVICE, YOU MUST NOT RELY ON THE
          SERVICE.
        </p>
        <p className="mb-2">
          SafariShare exerts efforts to provide you with high quality and
          satisfactory service. However, We do not warrant that the Service will
          operate in an uninterrupted or error-free manner, or that it will
          always be available or free from all harmful components, or that it is
          safe, secured from unauthorized access to SafariShare&rsquo;s
          computers, immune from damages, free of malfunctions, bugs or
          failures, including, but not limited to hardware failures, software
          failures and Software communication failures, originating either in
          SafariShare or any of its providers.
        </p>
        <p className="mb-2">
          SafariShare, INCLUDING ITS OFFICERS, DIRECTORS, SHAREHOLDERS,
          EMPLOYEES, SUB-CONTRACTORS AND AGENTS WILL NOT BE LIABLE FOR ANY
          DIRECT, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGE, OR ANY OTHER
          DAMAGE, AND LOSS (INCLUDING LOSS OF PROFIT AND LOSS OF DATA), COSTS,
          EXPENSES AND PAYMENTS, EITHER IN TORT, CONTRACTUAL, OR IN ANY OTHER
          FORM OF LIABILITY, ARISING FROM, OR IN CONNECTION WITH THE USE OF, OR
          THE INABILITY TO USE THE SERVICE, OR FROM ANY FAILURE, ERROR, OR
          BREAKDOWN IN THE FUNCTION OF THE SERVICE, OR FROM ANY FAULT, OR ERROR
          MADE BY OUR STAFF OR ANYONE ACTING ON ITS BEHALF, OR FROM YOUR
          RELIANCE ON THE CONTENT OF THE SERVICE, INCLUDING, WITHOUT LIMITATION,
          CONTENT ORIGINATING FROM THIRD PARTIES, OR FROM ANY COMMUNICATION WITH
          THE SERVICE, OR WITH OTHER USERS ON OR THROUGH THE SERVICE, OR FROM
          ANY DENIAL OR CANCELLATION OF YOUR USER ACCOUNT, OR FROM RETENTION,
          DELETION, DISCLOSURE AND ANY OTHER USE OR LOSS OF YOUR CONTENT ON THE
          SERVICE. IN ANY EVENT, YOUR SOLE REMEDY WILL BE LIMITED TO CORRECTING
          SUCH ERRORS, OR MALFUNCTIONS, AND IN LIGHT OF THE RELEVANT
          CIRCUMSTANCES.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">
          LINKS AND COMMERCIAL INFORMATION IN THE SOFTWARE
        </h4>
        <p className="mb-2">
          The Service may include commercial information or advertisements (for
          example, pins indicating the locations of certain establishments,
          their commercial offers, coupons, etc. (&ldquo;Ads&rdquo;). The Ads
          may pop up or be displayed on the Service maps from time to time.
          SafariShare may post Ads on the Service but more often than not, the
          source of such Ads is third parties, and as such, SafariShare cannot
          and does not guarantee the reliability or accuracy of third party Ads.
          SafariShare does not endorse the content of third party Ads.
          Additionally and without derogating from the previous sentence,
          SafariShare will not be liable for any form of liability arising from
          your reliance on, or in connection with, the use of the content of Ads
          posted on the Service.
        </p>
        <p className="mb-2">
          It is underscored that the advertising of commercial content by
          SafariShare does not constitute a recommendation or encouragement to
          procure the goods or services advertised.
        </p>
        <p className="mb-2">
          Insofar as the Software includes links to services or applications not
          operated or managed by SafariShare, SafariShare will not be liable for
          any form of liability arising from your reliance on, or in connection
          with, the content of such services and applications or any information
          provided by them, including but not limited to its completeness,
          accuracy, correctness or it being up-to-date. SafariShare will not be
          liable for any direct or indirect damage, monetary or otherwise,
          arising from your use of or your reliance on the content of goods or
          services you have accessed via Ads or other links on the Software.
        </p>
        <p className="mb-2">
          YOU MUST NOT ATTEMPT TO VIEW OR CLICK ON ANY ADS WHILE OPERATING A
          VEHICLE. YOU ALONE ARE RESPONSIBLE FOR DRIVING RESPONSIBLY, AND YOU
          ACKNOWLEDGE AND AGREE THAT IN THE EVENT THAT YOU VIOLATE THE FOREGOING
          PROVISION, THE THIRD PARTY ADVERTISERS WILL NOT BE LIABLE FOR ANY
          DIRECT, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGE, OR ANY OTHER
          DAMAGE, AND LOSS (INCLUDING LOSS OF PROFIT AND LOSS OF DATA), COSTS,
          EXPENSES AND PAYMENTS, EITHER IN TORT, CONTRACTUAL, OR IN ANY OTHER
          FORM OF LIABILITY, ARISING FROM, OR IN CONNECTION WITH THE DISPLAY OF
          THE ADS ON THE SERVICE OR YOUR VIEWING OF THE ADS ON THE SERVICE.
        </p>
        <h4 className="text-xl font-bold mt-8 mb-2">BREACH AND INDEMNITY</h4>
        <p>
          Without derogating from any applicable law, you agree to indemnify and
          hold harmless SafariShare and its employees, officers, directors and
          agents, as well as all third-party advertisers of Ads from and against
          all claims, damages, expenses, losses and liabilities that arise as a
          result of your violation of these Terms. In view of the fact that the
          Service is provided to you free-of-charge, this indemnity is intended
          to cover all expenses, payments, loss, loss of profits or any other
          damage, direct or indirect, monetary or non-monetary, incurred by
          SafariShare, its employees, officers, directors or agents as a result
          of your violation of the Terms, including but not limited to legal
          expenses and attorney fees.
        </p>
        <h4 className="text-xl font-bold mt-8 mb-2">PRIVACY</h4>
        <p>
          SafariShare respects your privacy during your use of the Software and
          the Service. Our updated privacy policy pertaining to the Software and
          the Service is readily accessible at our{" "}
          <a href="https://www.waze.com/legal/privacy">Privacy Policy</a>
          &nbsp;and is an integral part of these Terms. Since the privacy policy
          is subject to periodic updates, it is recommended that you
          periodically review the policy for updates.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">
          MODIFICATIONS TO THE SERVICE AND SOFTWARE
        </h4>
        <p>
          SafariShare may, either partially or in its entirety and without being
          obligated to provide prior notice &ndash; modify, adapt or change the
          Software, the Service&rsquo;s features, the user interface and design,
          the extent and availability of the contents in the Service and any
          other aspect related to the Service. You will have no claim, complaint
          or demand against SafariShare for applying such changes or for
          failures incidental to such changes.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">TERMINATION OF SERVICE</h4>
        <p>
          SafariShare may, at any time, terminate the provision of the Service
          in its entirety or any part thereof, temporarily or permanently, at
          its sole discretion.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">
          MODIFICATIONS OF THESE TERMS
        </h4>
        <p>
          SafariShare may modify these Terms from time to time. If fundamental
          changes are introduced, a notice will be posted in the updated version
          of the Software as well as on the Service&rsquo;s home page on the
          Site. Your continued use of the Service after the Terms have been
          modified signifies your assent to the updated Terms. If you dissent to
          the updated Terms or to any term within them, you must discontinue all
          further use of the Software.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">
          GOVERNING LAW AND JURISDICTION
        </h4>
        <p>
          These Terms, the Software and the Service will be governed solely by
          the laws of the State of Israel, without giving effect to any
          conflicts of law principles. Any dispute, claim or controversy arising
          out of, connected with or relating to these Terms, the Software and
          the Service will be under the exclusive jurisdiction of the competent
          court in the Tel Aviv district in Israel.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">LIMITATIONS</h4>
        <p>
          Should you desire to file any cause of action against SafariShare,
          arising out of or related to the SafariShare Software or Service, you
          must do so within one (1) year of the day you become aware of the
          cause of action. Failure to file a lawsuit within the aforementioned
          timeframe will bring about the permanent barring of the cause of
          action and will constitute your complete and final waiving of the
          lawsuit.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">ASSIGNMENT OF RIGHTS</h4>
        <p>
          You may not assign or transfer your rights in and to the Service,
          without the prior written consent of SafariShare. SafariShare may
          assign its rights in and to the Service to a third party at its sole
          and absolute discretion, provided that the third party undertakes
          SafariShare&rsquo;s obligations to you under these Terms.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">COMPLETE TERMS</h4>
        <p>
          These Terms, together with the policies that are an integral part of
          these Terms, namely the <a href="/privacy">Privacy Policy</a>&nbsp;and
          the Copyright Policy, shall all constitute the entire and complete
          agreement between you and SafariShare concerning the SafariShare
          Service. In the event of an inconsistency between these Terms and the
          synopsis of terms presented to the user during Software installation,
          these Terms shall prevail.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">NO LEGAL RELATIONSHIP</h4>
        <p>
          These Terms of Use and your use of the Service, including the
          submission of Content onto the Service, do not, and shall not be
          construed as creating any relationship, partnership, joint venture,
          employer-employee, agency, or franchisor-franchisee relationship in
          any way and of any kind between the parties hereto. Your use of the
          Service is intended for your enjoyment and benefit and the provision
          of the Service to you (subject to your compliance with these Terms)
          constitutes the sole and sufficient consideration that you are
          entitled to receive for any Content or other contributions you have
          made to the SafariShare Service, its contents, maps and any other
          data.
        </p>

        <h4 className="text-xl font-bold mt-8 mb-2">CONTACT US</h4>
        <p>
          You may contact us concerning any question about the Service, through
          the channels listed on the &ldquo;About&rdquo; menu in the Software or
          through the <a href="/contact">&ldquo;Contact Us&rdquo;</a>&nbsp;page.
          We will make our best efforts to address your inquiry promptly.
        </p>
        <p>&nbsp;</p>
      </div>
    </React.Fragment>
  );
};
