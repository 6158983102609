import * as React from "react";

import mutaka from "../assets/mutaka.webp";
import komujuni from "../assets/komujuni.webp";
import adda from "../assets/adda.webp";

interface Props {}

export const Reviews: React.FC<Props> = () => {
  return (
    <div className="container py-16" id="for-passengers">
      <h2 className="text-center mb-16 text-3xl md:text-4xl text-blue-600">
        What our customers say
      </h2>
      <div className="flex flex-col md:flex-row">
        <Review
          name="Komujuni Sarah"
          image={komujuni}
          body="Very relevant app ... The more pple sign up, the better!! Recently downloaded it & recommend it. I had such a good experience traveling upcountry. I look forward to more trips in the near future"
        />

        <div className="m-8"></div>

        <Review
          name="Emmanuel Mutaka"
          image={mutaka}
          body="I had my first ride, I loved it!!! Immediately I scheduled my route plan, I got 3 client requests! I loved my experience...so proud to be part of SafariShare family"
        />

        <div className="m-8"></div>

        <Review
          name="Addah Ampeire"
          image={adda}
          body="Over the top 👌experience. Important networks, friends that have turned into family. Well done developers"
        />
      </div>
    </div>
  );
};

const Review: React.FC<{
  name: string;
  image: string;
  body: string;
}> = props => {
  return (
    <div className="flex-1 flex flex-col">
      <div className="flex flex-row items-center">
        <img
          className="rounded-full"
          src={props.image}
          alt="Addah Ampeire"
          width={64}
        />
        <div className="m-2"></div>
        <div>
          <h2 className="text-xl">{props.name}</h2>
          <div className="flex flex-row">
            {[1, 2, 3, 4, 5].map(d => (
              <svg
                key={d}
                className="block h-4 w-4"
                fill="orange"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
              </svg>
            ))}
          </div>
        </div>
      </div>
      <div className="m-2"></div>
      <p className="text-sm p-4 bg-gray-100 rounded">{props.body}</p>
    </div>
  );
};
