import * as React from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";

import { client } from "../client";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import { Profile } from "../components/profile";
import { InviteRegistration } from "../features/registration/invite";
import { Loader } from "../components/loader";

export interface ParamTypes {
  companyUniqueToken: string;
}

export const Invitation: React.FC = () => {
  const [company, setCompany] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const history = useHistory();

  const { companyUniqueToken } = useParams<ParamTypes>();

  React.useEffect(() => {
    setLoading(true);
    client
      .get(`public/register/find-company/${companyUniqueToken}`)
      .then(({ data: { data: company } }) => {
        setLoading(false);
        setCompany(company);
      })
      .catch(() => {
        setLoading(false);
        history.push("/", { invalidLink: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyUniqueToken]);

  return (
    <div>
      <Helmet>
        <title>SafariShare &mdash; Commute with your colleagues</title>
      </Helmet>
      <Navigation />

      {loading ? (
        <Loader />
      ) : (
        <>
          <Profile
            members={company?.members}
            image={company?.logo}
            name={company?.name}
          />
          <InviteRegistration />
          <Footer />
        </>
      )}
    </div>
  );
};
