import React from "react";
import { RegistrationForm } from "../../components/registration-form";

export const PassengerRegistration = () => {
  return (
    <RegistrationForm
      type="traveller"
      title="Do you frequently travel long distance?"
      description="Join SafariShare as a passenger today and start travelling with trusted drivers near you instead of going to the park"
    />
  );
};
