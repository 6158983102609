import * as React from "react";
import { Helmet } from "react-helmet";

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { LongDistancePassenger } from "../components/Hero";
import { PassengerRegistration } from "../features/registration/passenger";

export const Travel: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>SafariShare &mdash; Travelling Long Distance</title>
      </Helmet>
      <Navigation />
      <LongDistancePassenger />
      <PassengerRegistration />
      <Footer />
    </div>
  );
};
