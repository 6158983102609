import * as React from "react";
import { Helmet } from "react-helmet";

import Navigation from "../components/Navigation";
import MainHero from "../components/Hero";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import { useLocation, useHistory } from "react-router-dom";
import ForDrivers from "../components/for-driver";
import { ForPassengers } from "../components/for-passengers";
import { ForEmployers } from "../components/for-employers";
import { Reviews } from "../components/reviews";
import { ForSchool } from "../components/for-schools";

interface LocationState {
  invalidLink: boolean;
}
export const Home: React.FC = () => {
  const locationState = useLocation<LocationState>();
  const history = useHistory();

  React.useEffect(() => {
    if (locationState?.state?.invalidLink) {
      alert("You entered an invalid invitation link!");
      history.replace({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  return (
    <div>
      <Helmet>
        <title>SafariShare &mdash; Commute &amp; Travel</title>
      </Helmet>
      <Navigation />
      <MainHero />
      <ForDrivers />
      <ForPassengers />
      <ForEmployers />
      <ForSchool />
      <Reviews />
      <Faq />
      <Footer />
    </div>
  );
};
