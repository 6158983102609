import * as React from "react";
import { Link } from "react-router-dom";

// stores
import appstore from "../assets/appstore.svg";
import playstore from "../assets/playstore.png";

//fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/free-brands-svg-icons";
import {
  faTwitter,
  faInstagram,
  faFacebook
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

export const Footer: React.FC = () => {
  return (
    <div style={{ backgroundColor: "#1a3660" }} className="py-12">
      <h3 className="text-white px-6 text-center font-semibold text-2xl">
        Available on PlayStore and AppStore
      </h3>
      {/** playstore & appstore */}
      <div className="flex justify-center">
        <a href="https://play.google.com/store/apps/details?id=co.safarishare.app">
          <img
            className="h-32 w-32 object-contain"
            src={playstore}
            alt="playstore"
          />
        </a>

        <div className="mx-2"></div>

        <a href="https://apps.apple.com/ug/app/safarishare/id1545451416">
          <img
            className="h-32 w-32 object-contain"
            src={appstore}
            alt="appstore"
          />
        </a>
      </div>
      {/** social links */}
      <div className="flex justify-center">
        <span className="mr-8">
          <a href="https://twitter.com/safarishareapp">
            <FontAwesomeIcon size="2x" color="white" icon={faTwitter} />
          </a>
        </span>
        <span className="mr-8">
          <a href="https://www.instagram.com/safarishareapp">
            <FontAwesomeIcon size="2x" color="white" icon={faInstagram} />
          </a>
        </span>
        <span className="mr-8">
          <a href="https://www.facebook.com/safarishareapp">
            <FontAwesomeIcon size="2x" color="white" icon={faFacebook} />
          </a>
        </span>
        <span className="mr-8">
          <a href="mailto://contact@safarishare.co">
            <FontAwesomeIcon size="2x" color="white" icon={faEnvelope} />
          </a>
        </span>
        <span className="mr-8">
          <a href="tel://+256393193885">
            <FontAwesomeIcon size="2x" color="white" icon={faPhone} />
          </a>
        </span>
      </div>

      <div className="md:flex justify-center text-center text-gray-300 mt-12 py-8 px-8">
        <div className="mr-6">
          <h4>
            © {new Date().getFullYear()} SafariShare is a product of Voyage
            Technologies Ltd
          </h4>
        </div>
      </div>

      <div className="flex items-center justify-center text-gray-300">
        <ul className="flex">
          <li className="mr-8 mt-2 text-sm cursor-pointer">
            <Link to="/terms">Terms of Use</Link>
          </li>
          <li className="mr-8 mt-2 text-sm cursor-pointer">
            <a href="/privacy">Privacy</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
