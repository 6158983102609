import * as React from "react";
import "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Loader = () => {
  return (
    <div className="flex justify-center align-middle p-28">
      <span>
        <FontAwesomeIcon
          size="10x"
          icon={faSpinner}
          className="animate-spin text-blue-600"
        />
      </span>
    </div>
  );
};
