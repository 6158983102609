import * as React from "react";
// import { Link } from "react-router-dom";

import travelHassleFree from "../assets/travel-hassle-free.jpg";

interface Props {}

export const ForPassengers: React.FC<Props> = () => {
  return (
    <div className="container" id="for-passengers">
      <div className="flex flex-col md:items-center md:flex-row py-16 md:py-32">
        <div
          className="md:flex-1 bg-blue-600 h-80"
          style={{
            backgroundImage: `url(${travelHassleFree})`,
            backgroundPosition: "center",
            backgroundSize: "cover"
          }}
        />
        <div className="m-4 md:m-16"></div>
        <div className="flex-1">
          <h2 className="text-3xl md:text-4xl text-blue-600">
            For <b>Passengers</b>
          </h2>
          <p className="text-xl md:text-2xl my-8 text-gray-600">
            Escape the hassle of public transport travelling with drivers going
            the same way. With SafariShare, you don't have to choose between
            convenience and affordability
          </p>
          {/* <Link to="/travel"  className="bg-blue-600 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Get Started
          </Link> */}
        </div>
      </div>
    </div>
  );
};
