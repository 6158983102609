import * as React from "react";
// import { Link } from "react-router-dom";

import "../styles/features.css";
import emptySeatsCash from "../assets/empty-seats-cash.jpg";

interface Props {}

export const ForDrivers: React.FC<Props> = () => {
  return (
    <div className="container" id="for-drivers">
      <div className="flex md:items-center flex-col-reverse md:flex-row py-16 md:py-32">
        <div className="md:flex-1">
          <h2 className="text-3xl md:text-4xl text-blue-600">
            For <b>Drivers</b>
          </h2>
          <p className="text-xl md:text-2xl my-8 text-gray-600">
            Cut 80% of your fuel cost by sharing empty seats on your daily
            commute or upcountry trip. Share ideas with new people everyday and
            grow your network
          </p>
          {/* <Link to="/drive" className="bg-blue-600 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Get Started
          </Link> */}
        </div>
        <div className="m-4 md:m-16"></div>
        <div
          className="md:flex-1 bg-blue-600 h-80 w-full"
          style={{
            backgroundImage: `url(${emptySeatsCash})`,
            backgroundPosition: "center",
            backgroundSize: "cover"
          }}
        />
      </div>
    </div>
  );
};

export default ForDrivers;
