import * as React from "react";
import { Helmet } from "react-helmet";

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { LongDistanceDriver } from "../components/Hero";
import { DriverRegistration } from "../features/registration/driver";

export const Drive: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>SafariShare &mdash; Driving Long Distance</title>
      </Helmet>
      <Navigation />
      <LongDistanceDriver />
      <DriverRegistration />
      <Footer />
    </div>
  );
};
