import * as React from "react";
import { Helmet } from "react-helmet";

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { What } from "../components/what";

export const About: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>SafariShare &mdash; Commute &amp; Travel</title>
      </Helmet>
      <Navigation />
      <What />
      <Footer />
    </div>
  );
};
