import * as React from "react";

export const Profile: React.FC<{
  image: string;
  name: string;
  members: number;
}> = props => {
  return (
    <React.Fragment>
      <div className="flex flex-col flex-wrap  pt-16">
        <div className="pb-8 flex justify-center align-middle">
          <img
            className="shadow  max-w-full h-32 rounded-full border-none"
            src={props.image}
            alt="company-logo"
          />
        </div>

        <div>
          <h2
            style={{ color: "#000000" }}
            className="text-2xl lg:text-4xl md:text-3xl sm:text-2xl md:text-center font-extrabold"
          >
            Join the {props.name} carpool
          </h2>

          <p
            style={{ color: "#808080" }}
            className="mt-5 md:text-center text-xl"
          >
            {props.members
              ? `${props.members}+ people have already joined`
              : `Be the first to join`}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
