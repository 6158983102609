import * as React from "react";
import "../styles/features.css";
import emptySeatsCash from "../assets/empty-seats-cash.jpg";

interface Props {}

export const What: React.FC<Props> = () => {
  return (
    <div className="container" id="for-drivers">
      <div className="flex flex-col-reverse md:flex-row py-16">
        <div className="flex-1">
          <h2 className="text-4xl text-blue-600">
            About <b>SafariShare</b>
          </h2>
          <p className="text-2xl my-8 text-gray-600">
            SafariShare is Uganda’s #1 carpooling platform for commute and
            intercity travel with a growing number of users, the platform
            connects passengers with drivers heading the same way to travel
            together and share the cost.
          </p>
        </div>
        <div className="m-8"></div>
        <div
          className="md:flex-1 bg-blue-600 h-80"
          style={{
            backgroundImage: `url(${emptySeatsCash})`,
            backgroundPosition: "center",
            backgroundSize: "cover"
          }}
        />
      </div>

      <div className="flex flex-col md:flex-row py-8">
        <div className="flex-1">
          <h2 className="text-2xl text-blue-600">
            We are <b>mission driven</b>
          </h2>
          <p className="text-xl my-8 text-gray-600">
            Our mission is to increase the energy efficiency of transport as a
            way to provide affordable transportation, reduce carbon emissions,
            and create greener spaces in our communities
          </p>
        </div>

        <div className="m-16"></div>

        <div className="flex-1">
          <h2 className="text-2xl text-blue-600">
            We are <b>ambitious</b>
          </h2>
          <p className="text-xl my-8 text-gray-600">
            Our goal is to be the #1 carpooling platform for commute and long
            distance travel in Africa filling up more than 1 billion empty seats
            per year
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row py-32">
        <div className="flex-1">
          <h2 className="text-4xl text-blue-600">
            Why we <b>exist</b>
          </h2>
          <p className="text-2xl my-8 text-gray-600">
            Passenger vehicles waste energy and space and the leading cause of
            air pollution, green house gas emissions and congestion. Especially
            in sub-Saharan Africa which being predemoninantly a used car market
            has some of the most polluting vehicles in the world.
          </p>

          <p className="text-2xl my-8 text-gray-600">
            Meanwhile, the underdeveloped public transport systems are
            inadequate to meet the growing transport needs in African cities
          </p>

          <div className="grid grid-cols-3 text-center gap-8 md:gap-32 text-blue-600 my-16">
            <div>
              <h2 className="text-4xl md:text-8xl">62%</h2>
              <p className="text-xs">
                Of pollution from road transport is contributed by private
                passenger cars
              </p>
            </div>
            <div>
              <h2 className="text-4xl md:text-8xl">75%</h2>
              <p className="text-xs">
                Of cars on the road are private passenger vehicles contributing
                the most to traffic
              </p>
            </div>
            <div>
              <h2 className="text-4xl md:text-8xl">~1.5</h2>
              <p className="text-xs">
                The average occupancy of private passengers cars in sub saharan
                Africa
              </p>
            </div>
          </div>

          <p className="text-2xl text-gray-600">
            It's going to take a long time to transition the African fleet to
            sustainable energy but climate change will not wait. We need
            solutions that can work today. Carpooling has been shown to be one
            of the best ways to reduce the impact of private passenger vehicles
            on the climate
          </p>
        </div>
      </div>

      <div className="pb-32">
        <h2 className="text-4xl text-blue-600">
          Who <b>we are</b>
        </h2>

        <div className="grid grid-cols-2 gap-4 md:gap-8 my-16 md:grid-cols-5">
          <div className="flex flex-col items-center">
            <img
              alt=""
              className="rounded-full h-full"
              src="https://media.licdn.com/dms/image/C4E03AQFtU4GVfYcATg/profile-displayphoto-shrink_800_800/0/1648276323475?e=1696464000&v=beta&t=D74et2HuWp8t4GoSROEr4jc48aQ_qgzONMBsxALCfiE"
            />
            <h2 className="text-xl">Ernest Okot</h2>
            <h3 className="text-sm text-gray-700">CEO</h3>
          </div>

          <div className="flex flex-col items-center ">
            <img
              alt=""
              className="rounded-full center h-full"
              src="https://pbs.twimg.com/profile_images/1230766619095261184/RuikZUlW.jpg"
            />
            <h2 className="text-xl">Tonny Butali</h2>
            <h3 className="text-sm text-gray-700">COO</h3>
          </div>

          <div className="flex flex-col items-center ">
            <img
              alt=""
              className="rounded-full center h-full"
              src="https://media.licdn.com/dms/image/C5603AQHdeURPFuuCvw/profile-displayphoto-shrink_800_800/0/1610124473107?e=1696464000&v=beta&t=tvdBhBetBFkuptFDWoX8-GtVp1TtMK6qGu6fS8RIcsc"
            />
            <h2 className="text-xl">Emmanuel Okwii</h2>
            <h3 className="text-sm text-gray-700">CSO</h3>
          </div>
        </div>
      </div>
    </div>
  );
};
