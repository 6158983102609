import * as React from "react";
import "../styles/features.css";
import emptySeatsCash from "../assets/classroom-kids.jpg";

interface Props {}

export const ForSchool: React.FC<Props> = () => {
  return (
    <div className="bg-green-600">
      <div className="container" id="for-employers">
        <div className="flex md:items-center flex-col md:flex-row py-16 md:py-32">
          <div
            className="md:flex-1 bg-blue-600 h-80"
            style={{
              backgroundImage: `url(${emptySeatsCash})`,
              backgroundPosition: "center",
              backgroundSize: "cover"
            }}
          />

          <div className="m-4 md:m-16"></div>

          <div className="flex-1">
            <h2 className="text-3xl md:text-4xl text-blue-600">
              For <b>Schools</b>
            </h2>
            <p className="text-2xl my-8 text-gray-800">
              Picking and dropping off school children accounts for a lot of
              traffic in our cities. Moreover, it is stressful for parents and
              children. We are exploring how SafariShare can partner with
              schools to enable safe pick ups and drop offs for school children.
            </p>
            <button className="bg-blue-600 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
