import * as React from "react";
import "../styles/hero.css";

//stores
import playstore from "../assets/playstore.png";
import appstore from "../assets/appstore.svg";
import phone from "../assets/searching.png";
import travelHassleFree from "../assets/travel-hassle-free.jpg";
import emptySeatsCash from "../assets/empty-seats-cash.jpg";

interface Props {
  subtitle?: string;
}

export const MainHero: React.FC<Props> = () => {
  return (
    <div className="bg-image bg-main bg-gradient w-full py-20">
      <div className="container mt-16 relative">
        <h3
          style={{ color: "#4dbbbd" }}
          className="text-3xl 2xl:text-7xl lg:text-5xl md:text-4xl sm:text-2xl font-extrabold"
        >
          Share a ride, make friends
        </h3>
        <h3
          style={{ color: "#fff" }}
          className="text-3xl 2xl:text-7xl lg:text-5xl md:text-4xl sm:text-2xl font-extrabold"
        >
          and save money
        </h3>

        <div className="flex">
          <a href="https://play.google.com/store/apps/details?id=co.safarishare.app">
            <img
              className="h-32 w-32 xl:h-48 xl:w-48 2xl:h-64 2xl:w-64 object-contain"
              src={playstore}
              alt="playstore"
            />
          </a>
          <div className="mx-2"></div>
          <a href="https://apps.apple.com/ug/app/safarishare/id1545451416">
            <img
              className="h-32 w-32 xl:h-48 xl:w-48 2xl:h-64 2xl:w-64 object-contain"
              src={appstore}
              alt="appstore"
            />
          </a>
        </div>

        <div className="hidden md:block">
          <img
            className="absolute -bottom-32 right-8 lg:right-16 w-48 xl:w-64"
            src={phone}
            alt="App"
          />
        </div>
      </div>
    </div>
  );
};

export const LongDistanceDriver: React.FC<Props> = () => {
  return (
    <HalfHero
      image={emptySeatsCash}
      title="Turn those empty seats"
      title2="into cash for fuel"
      subtitle="Travel safely with our trusted drivers taking the same route"
    />
  );
};

export const LongDistancePassenger: React.FC<Props> = () => {
  return (
    <HalfHero
      image={travelHassleFree}
      title="Avoid the hassle"
      title2="of public transport"
      subtitle="Travel safely with passengers taking the same route"
    />
  );
};

export const HalfHero: React.FC<{
  image: string;
  title: string;
  title2: string;
  subtitle?: string;
}> = props => {
  return (
    <React.Fragment>
      <div
        className="bg-image bg-half bg-gradient w-full flex flex-col justify-end px-8 pb-8 pt-24"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(16, 19, 33, 0.52), #05060b),
        url("${props.image}")`
        }}
      >
        <div>
          <h2
            style={{ color: "#fff" }}
            className="text-4xl lg:text-5xl md:text-4xl sm:text-2xl md:text-center font-extrabold"
          >
            {props.title}
          </h2>
          <h2
            style={{ color: "#4dbbbd" }}
            className="text-3xl lg:text-5xl md:text-4xl sm:text-2xl md:text-center font-extrabold"
          >
            {props.title2}
          </h2>
          <p style={{ color: "#fff" }} className="mt-5 md:text-center text-xl">
            {props.subtitle}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainHero;
