import React, { FC, FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";

import { client } from "../client";
import { AppDownloadLinks } from "./app-download-links";

interface Props {
  type: "driver" | "traveller";
  title: string;
  description: string;
}

export const RegistrationForm: FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const formData = new FormData(event.target as any);

    setLoading(true);
    client
      .post<{ message: string }>("/public/register", {
        name: formData.get("name"),
        email: formData.get("email"),
        phone: formData.get("phone"),
        role: props.type,
        promo: formData.get("promo")
      })
      .then(response => response.data)
      .then(data => {
        setLoading(false);
        setMessage(data.message);
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        alert(
          error.response?.data.message ||
            "Something went wrong, please try again!"
        );
      });
  }

  if (message) {
    return <AppDownloadLinks message={message} />;
  }

  return (
    <form
      className="md:container md:mx-auto px-4 py-12"
      onSubmit={handleSubmit}
    >
      <h2 className="text-2xl text-center font-bold">{props.title}</h2>
      <div className="max-w-md md:mx-auto">
        <p className="mt-2 text-lg text-center text-gray-600">
          {props.description}
        </p>
        <div className="mt-8">
          <div className="grid grid-cols-1 gap-6">
            <label className="block">
              <span className="text-gray-700">Full name</span>
              <input
                name="name"
                type="text"
                className="mt-1 block w-full"
                required
              />
            </label>
            <label className="block">
              <span className="text-gray-700">Phone Number</span>
              <input name="phone" type="tel" className="mt-1 block w-full" />
            </label>
            <label className="block">
              <span className="text-gray-700">Email address</span>
              <input name="email" type="email" className="mt-1 block w-full" />
            </label>
            <label className="block">
              <span className="text-gray-700">Promo Code</span>
              <input name="promo" type="text" className="mt-1 block w-full" />
            </label>
            <div className="block">
              <div className="mt-2">
                <div>
                  <label className="inline-flex items-center">
                    <input type="checkbox" />
                    <span className="ml-2">
                      I accept{" "}
                      <Link to="/terms">
                        <b>terms of use</b>
                      </Link>{" "}
                      and{" "}
                      <Link to="/privacy">
                        <b>privacy policy</b>
                      </Link>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="block">
              <button className="bg-blue-600 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                {loading ? "Saving..." : "Sign Up"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
