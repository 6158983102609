import * as React from "react";
import { useTransition, animated } from "react-spring";
import { Link } from "react-router-dom";

//fontawesome
import "@fortawesome/react-fontawesome";
import "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export const Navigation: React.FC = () => {
  const [showMenu, setShowMenu] = React.useState<boolean>(false);

  const menuTransition = useTransition(showMenu, null, {
    from: { opacity: 0, transform: "translateX(-100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: "translateX(-100%)" }
  });

  //screen
  const screen = (
    <div className="hidden md:flex items-center justify-center text-xl">
      <ul className="flex">
        <li className="mt-2 cursor-pointer">
          <Link to="/">Home</Link>
        </li>
        <div className="mr-8"></div>
        <li className="mt-2 cursor-pointer">
          <a href="/corporate">Corporate</a>
        </li>
        <div className="mr-8"></div>
        {/* <li className="mt-2 cursor-pointer">
          <a href="/corporate">Schools</a>
        </li>
        <div className="mr-8"></div> */}
        <li className="mt-2 cursor-pointer">
          <a href="/about">About</a>
        </li>
        <div className="mr-8"></div>
        <li className="mt-2 cursor-pointer">
          <a href="/guidelines">COVID19 Guidelines</a>
        </li>
      </ul>
    </div>
  );

  const mobile = (
    <div
      className="sm:block md:hidden z-20"
      onClick={() => setShowMenu(!showMenu)}
    >
      <div className="py-2 px-4">
        <span>
          <FontAwesomeIcon size="lg" icon={faBars} />
        </span>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <header className="w-full z-10 bg-blue-600 text-white py-2">
        <div className="container flex justify-between">
          {/** logo */}
          <Link to="/">
            <img src="/banner.png" style={{ height: 54 }} alt="Logo" />
          </Link>

          {/** screen */}
          {screen}

          {/** mobile */}
          {mobile}
        </div>
      </header>
      {/** mobile slide */}
      <div className={showMenu ? "block" : "hidden"}>
        {menuTransition.map(
          ({ item, key, props }) =>
            item && (
              <animated.div
                style={props}
                key={key}
                className="fixed z-20 shadow-lg top-0 left-0 w-3/4 h-full bg-blue-600 text-white"
              >
                <ul className="px-8 mt-24">
                  <li className="mr-8 mt-6 text-2xl cursor-pointer hover:text-green-600">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="mr-8 mt-8 text-2xl cursor-pointer hover:text-green-600">
                    <Link to="/corporate">Corporate</Link>
                  </li>
                  <li className="mr-8 mt-8 text-2xl cursor-pointer hover:text-green-600">
                    <Link to="/about">About</Link>
                  </li>
                  <li className="mr-8 mt-8 text-2xl cursor-pointer hover:text-green-600">
                    <Link to="/guidelines">COVID19 Guidelines</Link>
                  </li>
                </ul>
              </animated.div>
            )
        )}
      </div>
    </React.Fragment>
  );
};

export default Navigation;
