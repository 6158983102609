import * as React from "react";
import {
  UserGroupIcon,
  OfficeBuildingIcon,
  PresentationChartLineIcon
} from "@heroicons/react/outline";
import "../styles/features.css";
import happyEmployees from "../assets/share-empty-seats.jpg";
import csr from "../assets/csr.jpg";
import { Link } from "react-router-dom";
import { client } from "../client";
import { AxiosError } from "axios";

interface Props {}

export const CorporateHeader: React.FC<Props> = () => {
  return (
    <div
      className="flex flex-row bg-blue-600 h-screen items-center bg-cover bg-right md:bg-center"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(16, 19, 33, 0.52), #05060b), url(${happyEmployees})`
      }}
    >
      <div className="container">
        <div className="grid grid-cols-2">
          <div className="col-span-2 md:col-span-1 text-white">
            <h2 className="text-sm font-bold">CORPORATE CARPOOL</h2>
            <div className="mb-4"></div>
            <h2 className="text-3xl md:text-6xl font-bold">Carpool to Work</h2>
            <div className="mb-4"></div>
            <p className="text-xl text-white">
              Start your company carpooling program and let your employees
              commute to work safely and affordably with SafariShare
            </p>
            <div className="mb-8"></div>
            <a
              href="#request-access"
              className="bg-green-600 hover:bg-green-600 text-blue-600 font-bold py-4 px-8 rounded"
            >
              Get Started
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Benefits: React.FC<Props> = () => {
  return (
    <div className="bg-white">
      <div className="container">
        <div className="py-16 md:py-32 text-center">
          <h2 className="text-3xl md:text-5xl font-bold">
            Benefits at Every Level
          </h2>
          <div className="m-16"></div>
          <p className="text-xl text-gray-600"></p>

          <div className="flex flex-col md:flex-row">
            <div className="flex-1 flex flex-col items-center bg-gray-100 p-8 rounded-2xl">
              <UserGroupIcon className="h-16 w-16 text-blue-600" />
              <div className="m-4"></div>
              <h2 className="text-3xl">Employees</h2>
              <div className="m-4"></div>
              <p>
                Carpooling means less parking problems, lower stress levels in
                the morning & cheaper commute for the employees. It also
                provides opportunities to strengthen social connections.
              </p>
            </div>
            <div className="m-4"></div>
            <div className="flex-1 flex flex-col items-center bg-gray-100 p-8 rounded-2xl">
              <PresentationChartLineIcon className="h-16 w-16 text-blue-600" />
              <div className="m-4"></div>
              <h2 className="text-3xl">HR</h2>
              <div className="m-4"></div>
              <p>
                Carpooling helps to increase employee satisfaction &
                productivity not to mention reducing the impact of commuting on
                the environment, which helps to achieve CSR goals.
              </p>
            </div>
            <div className="m-4"></div>
            <div className="flex-1 flex flex-col items-center bg-gray-100 p-8 rounded-2xl">
              <OfficeBuildingIcon className="h-16 w-16 text-blue-600" />
              <div className="m-4"></div>
              <h2 className="text-3xl">Company</h2>
              <div className="m-4"></div>
              <p>
                Carpooling not only helps save money of the carpoolers, but also
                the company. It reduces parking occupancy and thus also the
                facility costs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CSR: React.FC<Props> = () => {
  return (
    <div className="bg-green-600">
      <div className="container">
        <div className="py-16 md:py-16">
          <div className="flex flex-col-reverse md:flex-row items-center">
            <div className="flex-1">
              <h2 className="text-4xl font-bold">
                Demonstrate Social Responsibility
              </h2>
              <div className="m-4"></div>
              <p className="text-xl">
                Today’s leading enterprises take responsibility for their social
                and environmental impact on society. The average daily commute
                to work is 30 kilometers per day therefore, a shared ride to
                work with colleagues saves up to 10 tons of CO2 per year.
                Embrace carpooling to show social responsibility and leadership
                to your employees and the community.
              </p>
            </div>
            <div className="m-8"></div>
            <div
              className="md:flex-1 h-72 w-full bg-cover bg-center"
              style={{
                backgroundImage: `url(${csr})`
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WhyCorporate: React.FC<Props> = () => {
  return (
    <div className="bg-white">
      <div className="container">
        <div className="py-16 md:py-16 text-center">
          <h2 className="text-3xl md:text-4xl text-blue-600">
            Why Partner With SafariShare
          </h2>
          <div className="m-8"></div>
          <p className="text-xl text-gray-600">
            Our corporate carpool program is 100% free for organisations
            including onboarding and training
          </p>
        </div>
      </div>
    </div>
  );
};

export const Offer: React.FC<Props> = () => {
  return (
    <div className="bg-blue-600">
      <div className="container">
        <div className="flex flex-col md:flex-row items-center px-4 py-16 md:py-32">
          <div className="flex-1">
            <h2 className="text-3xl md:text-4xl text-white font-bold">
              What We Offer
            </h2>
            <div className="m-8"></div>
            <p className="text-xl md:text-xl text-gray-200">
              SafariShare is everything you need to launch a carpooling program
              at your company – from technology to hands-on expertise. Our
              solution is fully customizable and scalable, so no matter what
              size is your organization, we can help you with a successful
              implementation – all 100% free.
            </p>
          </div>
          <div className="md-4 md:m-8"></div>
          <div className="flex-1 m-8 md:m-0">
            <ul className="list-disc text-xl text-white">
              <li>Free assessment of the viability and benefits</li>
              <li>Custom configuration to fit your needs</li>
              <li>iOS and Android app for your employees</li>
              <li>Web admin panel with analytics & reporting</li>
              <li>Ongoing support</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RequestAccess: React.FC<Props> = () => {
  const [loading, setLoading] = React.useState(false);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    const prospect = Object.fromEntries(new FormData(event.target as any));

    client
      .post<void>("/public/register/prospects", prospect)
      .then(response => response.data)
      .then(() => {
        setLoading(false);
        alert("Your message was sent successfully, thank you!");
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        alert(
          error.response?.data.message ||
            "Something went wrong, please try again!"
        );
      });
  }

  return (
    <div className="bg-green-600" id="request-access">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-8">
          <div className="md:col-start-2 lg:col-start-3 md:col-span-4 bg-white rounded-lg my-16 p-4 md:p-8 shadow-2xl">
            <div className="text-center">
              <h2 className="text-3xl md:text-4xl text-blue-600 font-bold">
                Get Started
              </h2>
              <div className="m-8"></div>
              <p className="text-sm text-blue-600">
                Interested in a carpooling program at your organisation?
              </p>
              <p className="text-sm text-blue-600">
                Get in touch with us and we will get back to you as soon as
                possible
              </p>
              <div className="m-8"></div>
            </div>

            <form
              onSubmit={handleSubmit}
              className="md:mx-16 mb-16 grid grid-cols-1 gap-6"
            >
              <label className="block">
                <span className="text-gray-700">Name</span>
                <input
                  name="name"
                  type="text"
                  className="mt-1 block w-full"
                  required
                />
              </label>
              <label className="block">
                <span className="text-gray-700">Work Email</span>
                <input
                  name="email"
                  type="email"
                  className="mt-1 block w-full"
                  required
                />
              </label>
              <label className="block">
                <span className="text-gray-700">Phone</span>
                <input
                  name="phone"
                  type="tel"
                  className="mt-1 block w-full"
                  required
                />
              </label>
              <label className="block">
                <span className="text-gray-700">Company Name</span>
                <input
                  name="company"
                  type="text"
                  className="mt-1 block w-full"
                  required
                />
              </label>
              <label className="block">
                <span className="text-gray-700">Office Location</span>
                <input
                  name="address"
                  type="text"
                  className="mt-1 block w-full"
                  required
                />
              </label>
              <label className="block">
                <span className="text-gray-700">Number of Employees</span>
                <select name="employees" className="mt-1 block w-full" required>
                  <option value="" disabled selected>
                    Select the Number of Employees
                  </option>
                  <option value="0-50">0-50</option>
                  <option value="50-100">50-100</option>
                  <option value="100-200">100-200</option>
                  <option value="200-500">200-500</option>
                  <option value="500-1000">500-1000</option>
                  <option value="1000+">1000+</option>
                </select>
              </label>
              <div className="block">
                <div className="mt-2">
                  <div>
                    <label className="inline-flex items-center">
                      <input type="checkbox" required />
                      <span className="ml-2">
                        I accept{" "}
                        <Link to="/terms">
                          <b>terms of use</b>
                        </Link>{" "}
                        and{" "}
                        <Link to="/privacy">
                          <b>privacy policy</b>
                        </Link>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="block text-center">
                <button className="bg-blue-600 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                  {loading ? "Sending..." : "Contact Us"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
