import * as React from "react";
import "../styles/features.css";
import emptySeatsCash from "../assets/happy-employees.jpg";
import { Link } from "react-router-dom";

interface Props {}

export const ForEmployers: React.FC<Props> = () => {
  return (
    <div className="bg-blue-600">
      <div className="container" id="for-employers">
        <div className="flex md:items-center flex-col-reverse md:flex-row py-16 md:py-32">
          <div className="md:flex-1">
            <h2 className="text-3xl md:text-4xl text-green-600">
              For <b>Employers</b>
            </h2>
            <p className="text-2xl my-8 text-gray-200">
              Foster carpooling culture within your organisation and help your
              employees get to work faster and happier no matter the weather.
              Demonstrate your commitment to sustainability. Our Corporate
              Carpool is 100% free for organisations!
            </p>
            <Link
              to="/corporate"
              className="bg-green-600 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
            >
              Learn More
            </Link>
          </div>

          <div className="m-4 md:m-16"></div>

          <div
            className="md:flex-1 bg-blue-600 h-80"
            style={{
              backgroundImage: `url(${emptySeatsCash})`,
              backgroundPosition: "center",
              backgroundSize: "cover"
            }}
          />
        </div>
      </div>
    </div>
  );
};
