import * as React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { Home } from "./pages/home";
import { Privacy } from "./pages/privacy";
import { Terms } from "./pages/terms";
import { Drive } from "./pages/travel-driver";
import { Travel } from "./pages/travel-passenger";
import { Invitation } from "./pages/invitation";
import { About } from "./pages/about";
import { Corporate } from "./pages/corporate";
import { Guidelines } from "./pages/covid-guidelines";

export const App: React.FC = () => {
  const history = useHistory();

  React.useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => unlisten();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route path="/terms">
        <Terms />
      </Route>
      <Route path="/privacy">
        <Privacy />
      </Route>
      <Route path="/drive">
        <Drive />
      </Route>
      <Route path="/travel">
        <Travel />
      </Route>
      <Route path="/invite/:companyUniqueToken">
        <Invitation />
      </Route>
      <Route path="/about">
        <About />
      </Route>
      <Route path="/corporate">
        <Corporate />
      </Route>
      <Route path="/guidelines">
        <Guidelines />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
};
