import * as React from "react";
import { Helmet } from "react-helmet";

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import {
  CorporateHeader,
  Benefits,
  RequestAccess,
  Offer,
  CSR
} from "../components/corporate";

export const Corporate: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>SafariShare &mdash; Corporate &amp; Carpool</title>
        <meta
          name="description"
          content="Start your company carpooling program and let your employees
          commute to work safely and affordably with SafariShare"
        />
      </Helmet>
      <Navigation />
      <CorporateHeader />
      <Offer />
      <CSR />
      <Benefits />
      <RequestAccess />
      <Footer />
    </div>
  );
};
