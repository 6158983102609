import React, { FormEvent, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { client } from "../client";
import { AppDownloadLinks } from "./app-download-links";
import { ParamTypes } from "../pages/invitation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const InvitationForm = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [agreeWithTerms, setAgreeWithTerms] = useState<boolean>(false);

  const { companyUniqueToken } = useParams<ParamTypes>();

  const setagreeWithTerms = (e: any) => {
    const checked = e.target.checked;
    checked ? setAgreeWithTerms(true) : setAgreeWithTerms(false);
  };
  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const formData = new FormData(event.target as any);
    const name = formData.get("name");
    const email = formData.get("email");

    if (!(name && email && phone)) {
      return alert("All fields are required");
    }

    const requestBody = {
      name,
      email,
      phone: `+${phone}`,
      companyUniqueToken
    };

    setLoading(true);

    client
      .post<{ message: string }>(
        "/public/register/through-inivitation/",
        requestBody
      )
      .then(response => response.data)
      .then(data => {
        setLoading(false);
        setMessage(data.message);
        window.scrollTo({ top: 0 });
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        alert(
          error.response?.data.message ||
            "Something went wrong, please try again!"
        );
      });
  }

  if (message) {
    return <AppDownloadLinks />;
  }

  return (
    <form className="md:container md:mx-auto px-4 py-8" onSubmit={handleSubmit}>
      <div className="max-w-md md:mx-auto">
        <div className="mt-8">
          <div className="grid grid-cols-1 gap-6">
            <label className="block">
              <span className="text-gray-700">Full name</span>
              <input
                name="name"
                type="text"
                className="mt-1 block w-full"
                required
              />
            </label>
            <label className="block">
              <span className="text-gray-700">Phone Number</span>
              <PhoneInput
                inputStyle={{
                  width: "100%",
                  border: "1px solid #6b7280",
                  height: "42px",
                  borderRadius: "0px"
                }}
                onlyCountries={["ug", "ke"]}
                countryCodeEditable={false}
                country={"ug"}
                value={phone}
                onChange={phone => setPhone(phone)}
              />
            </label>

            <label className="block">
              <span className="text-gray-700">Email address</span>
              <input name="email" type="email" className="mt-1 block w-full" />
            </label>

            <div className="block">
              <div className="mt-2">
                <div>
                  <label className="inline-flex items-center">
                    <input type="checkbox" onChange={setagreeWithTerms} />
                    <span className="ml-2">
                      I accept{" "}
                      <Link to="/terms">
                        <b>terms of use</b>
                      </Link>{" "}
                      and{" "}
                      <Link to="/privacy">
                        <b>privacy policy</b>
                      </Link>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="block text-center">
              <button
                className="bg-blue-600 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                disabled={!agreeWithTerms}
              >
                {loading ? "Saving..." : "Join"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
